import React, {useState} from 'react'

// markup
const QABox = ({q, a}) => {
  const [open, setOpen] = useState(false)
  // This is arranged in reverse so we can show the Q on top always
  return (
    <div className="qa"> 
      {open &&
        <div className="a" dangerouslySetInnerHTML={{__html: a}} />
      }
      <div className="q" onClick={() => {
        setOpen(!open)
      }}>
        <div className="q-text">{q}</div>
        {open ? (
          <span>–</span>
        ) : (
          <span>+</span>
        )}
      </div>
    </div>
  )
}

export default QABox
