import React, {useEffect, useState, useRef, Fragment} from 'react'
import Layout from '../components/layout'
import Validate from '../components/validate'
import Nav from '../components/ui/nav'
import { navigate, Link } from 'gatsby'
import ComboBox from 'react-responsive-combo-box'
import Footer from '../components/landing/footer'
import QABox from '../components/ui/qa'
import ContactBox from '../components/ui/contact'

// markup
const FAQ = () => {

  return (
    <Layout>
      <main>
        <div className="non-landing white">
          <Nav page={"faq"} items={[
            {url: "/#register", text: "Request demo"},
            {url: "/contact/", text: "Contact Us"},
            {url: "/app/", text: "Login"}
          ]} />
          <div className="topper">
            <div className="img-wrapper">
              <img src="/images/topperfaq.jpg" alt="An angular slice of a road" />
            </div>
            <div className="deck-wrapper">
              <div className="deck">
                <h1>
                  Frequently Asked Questions
                </h1>
              </div>
            </div>
          </div>
        </div>
        <section className="app-body support help">
          <div className="help-container">
            <p><strong>Are you a PaverOps member looking for help with using the app? <Link to="/support/helpcenter">Visit the Help Center</Link>.</strong></p>
            <QABox 
              q="How does membership for PaverOps work?"
              a={`
                <p>PaverOps is a subscription service that provides subscribers with infrastructure coordination, project coordination, and utility infrastructure data for paving to provide easier budgeting over a longer term. A PaverOps subscription is available on an annual or multi-year basis and includes membership and support.</p>
              `}
            />
            <QABox 
              q="Is support included in my PaverOps subscription?"
              a={`
                <p>Absolutely. Comprehensive support is a standard with an organization’s subscription.</p>
              `}
            />
            <QABox 
              q="Which types of organizations can become a PaverOps member?"
              a={`
                <p>PaverOps is designed to increase and simplify collaboration, communication, and access to reliable and actionable data between organizations that serve the public interest. PaverOps partners with towns, cities, counties, states, local and regional agencies, special utility districts, public and private utilities, and other organizations with underground infrastructure.</p> 
              `}
            />
            <QABox 
              q="Our organization uses ESRI for our internal GIS. Is PaverOps compatible with ESRI systems and data?"
              a={`
                <p>Yes. PaverOps is an ESRI partner and actually a member of their Startup Program. PaverOps was developed using ESRI technology to specifically and seamlessly support the many local and regional governments or agencies, utilities, and other public service organizations already using ESRI in-house.</p>
              `}
            />
            <QABox 
              q="My organization does not use ESRI. Is this a problem if we want to become PaverOps members?"
              a={`
                <p>Not a problem at all. PaverOps is an intuitive, easy-to-use, yet robust GIS-centric program that any organization can use.</p> 
              `}
            />
            <QABox 
              q="Will PaverOps sell our data?"
              a={`
                <p>Absolutely not. Many companies want to gather your data in order to sell it to the highest private bidder. This compromises your data, does not sow trust, and does not help improve your organization’s operations. It is against our values and goals here at PaverOps. Your data is never sold.</p> 
              `}
            />
            <QABox 
              q="How long does it take to get started with PaverOps?"
              a={`
                <p>Because nothing needs to be downloaded or installed and PaverOps lives in the cloud, your organization can begin benefiting and contributing to the community almost immediately after your subscription is finalized.</p> 
              `}
            />
            <QABox 
              q="My organization’s service area polygon needs to be updated. How do we do that?"
              a={`
                <p>Ask your organization’s PaverOps admin to send an email to support@paverops.com with the updated service area attached (must be a shapefile, feature class, or GeoJSON), and with a subject line of “[Insert your Org name] updated service area”.</p> 
              `}
            />
            <QABox 
              q="How much does PaverOps cost?"
              a={`
                <p>PaverOps uses an equitable subscription pricing model based on an organization’s population served, for municipalities and counties, or service connections, for utilities. Please use the “Contact Us” link to learn more.</p> 
              `}
            />
            <ContactBox text="Don't see your question answered?" register={true} />
          </div>
        </section>
        <Footer />
      </main>
    </Layout>
  )
}

export default FAQ

